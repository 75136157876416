import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = 'settings/order/service-order'

const Actions = crudActions(endpoint)
const getServiceOrders = (ctx, data) => axios.get(`${endpoint}`, data)
const updateServiceOrders = (ctx, data) => axios.post(`${endpoint}`, data)
export default {
  ...Actions,
  getServiceOrders,
  updateServiceOrders,
}
