<template>
  <div>
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        class="ml-1 cursor-pointer"
        @click="isEditAble()"
      />
    </div>
    <b-form>
      <error-alert
        :fields="serviceOrdersFields"
        :error="error"
      />
      <validation-observer
        ref="serviceOrdersUpdateVal"
      >
        <service-order-quotes :is-edit="isEdit" />
        <service-orders-templates :is-edit="isEdit" />
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="$router.push({ name: 'settings' })"
              >
                {{ $t('Back to Setting') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>
import { BButton, BForm, VBTooltip } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@/libs/validations/validations'
import config from '@/views/settings/orders/service-orders/serviceOrdersConfig'
import ServiceOrderQuotes from '@/views/settings/orders/service-orders/components/ServiceOrdersQuotes.vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { scrollToError } from '@core/utils/utils'
import ServiceOrdersTemplates from '@/views/settings/orders/service-orders/components/ServiceOrdersTemplates.vue'
import store from '@/store'

export default {
  name: 'ServiceOrdersForm',
  components: {
    ErrorAlert,
    ServiceOrderQuotes,
    ServiceOrdersTemplates,
    BButton,
    BForm,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      error: {},
      required,
      onSubmit: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    serviceOrders() {
      return this.$store.state[this.MODULE_NAME].serviceOrdersForm
    },
    serviceOrdersFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].settingsServiceOrdersFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.serviceOrders) !== this.serviceOrdersFormClone
    },
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },

    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.serviceOrdersUpdateVal.validate()
        .then(success => {
          if (success) {
            const data = this.serviceOrders
            const serviceOrders = this.mappingFields(Object.keys(this.serviceOrdersFields), data, {
              service_orders_enable_in_house: data.service_orders_enable_in_house ? 'true' : 'false',
              service_orders_templates_email: String(data.service_orders_templates_email),
              service_orders_templates_pdf: String(data.service_orders_templates_pdf),
            })
            this.sendNotification(this, serviceOrders, `${this.MODULE_NAME}/updateServiceOrders`)
              .then(() => {
                this.$emit('refetch')
              })
              .catch(err => {
                this.error = err.response?.data
              }).finally(() => {
                this.onSubmit = false
              })
          } else {
            scrollToError(this, this.$refs.customerSettingsCreateVal)
          }
        })
    },
    loader() {
      this.$refs.serviceOrdersUpdateVal.reset()
      const smth = JSON.parse(this.serviceOrdersFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
    getProps(fieldName) {
      return {
        field: this.serviceOrdersFields[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const { serviceOrdersFields, MODULE_NAME, MODULE_NAME_CLONE } = config()

    return {
      serviceOrdersFields,
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
