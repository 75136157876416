<template>
  <div>
    <h2
        class="font-weight-bolder font-medium-4"
    >
      {{ $t('Service Quotes') }}
    </h2>
    <b-row>
      <b-col md="12">
        <div
            v-for="field in ['service_orders_enable_in_house']"
            :key="field"
            style="height: 70px; margin-bottom: 10px"
        >
          <b-skeleton
              height="30px"
              style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
    <h2
        class="font-weight-bolder font-medium-4 mt-2"
    >
      {{ $t('Templates') }}
    </h2>
    <b-row>
      <b-col md="12">
        <div
            v-for="field in ['service_orders_templates_email', 'service_orders_templates_pdf']"
            :key="field"
            style="height: 170px; margin-bottom: 10px"
        >
          <span
              class="font-weight-bolder d-block"
              style="margin-bottom: 5px; margin-left: 10px"
          >{{ serviceOrdersFields[field].label }}</span>
          <b-skeleton
              height="150px"
              style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCol, BRow, BSkeleton } from 'bootstrap-vue'
import config from '../serviceOrdersConfig'

export default {
  name: 'ServiceOrdersSkeleton',
  components: { BRow, BCol, BSkeleton },
  setup() {
    const { serviceOrdersFields } = config()
    return {
      serviceOrdersFields,
    }
  },
}
</script>
