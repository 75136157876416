<template>
  <div>
    <skeleton v-if="loading"/>
    <service-orders-form
        v-else
        ref="service-orders-form"
        @refetch="refetch"
    ></service-orders-form>
  </div>
</template>

<script>
import ServiceOrdersForm from "@/views/settings/orders/service-orders/components/ServiceOrdersForm.vue";
import store from "@/store";
import serviceOrdersModule from "@/store/settings/orders/service-orders";
import {onUnmounted} from "@vue/composition-api";
import Skeleton from "@/views/settings/orders/service-orders/components/Skeleton.vue";
import config from "@/views/settings/orders/service-orders/serviceOrdersConfig";

export default {
  name: 'ServiceOrders',
  components: {Skeleton, ServiceOrdersForm},
  data() {
    return {
      loading: false,
    }
  },
  created() {
    this.$emit('updateMenu', 'settings-orders-service')
    this.refetch()
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['service-orders-form']) this.$refs['service-orders-form'].showConfirmationModal(next)
  },
  methods: {
    refetch() {
      this.loading = true
      this.$store.dispatch(`${this.MODULE_NAME}/getServiceOrders`).then(res => {
        const {data} = res.data
        const obj = {}
        data.forEach(({key, value}) => {
          obj[key] = value
        })
        this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, obj)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_ORDER_SERVICE_ORDERS_CLONE`, obj)
      }).catch(err => {
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false
      })
    },
  },
  setup() {
    const {MODULE_NAME, MODULE_NAME_CLONE, serviceOrdersFields} = config()
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, serviceOrdersModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      serviceOrdersFields,
    }
  },
}

</script>