<template>
  <div class="mt-5">
    <h2 class="font-weight-bolder font-medium-4">
      {{ $t('Templates') }}
    </h2>
    <b-row>
      <b-col md="12">
        <component
          :is="serviceOrdersFields[field].type"
          v-for="field in ['service_orders_templates_email', 'service_orders_templates_pdf']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="serviceOrders[field]"
          v-bind="getProps(field, serviceOrdersFields)"
          :is-editable="!$can(ACCESS_ABILITY_SETTINGS_ORDERS.action,ACCESS_ABILITY_SETTINGS_ORDERS.subject) || isEdit"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCol, BRow } from 'bootstrap-vue'
import { getProps } from '@core/utils/utils'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import config from '../serviceOrdersConfig'

export default {
  name: 'TransferOrdersTemplates',
  components: { ErrorAlert, BRow, BCol },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
    }
  },
  computed: {
    serviceOrders() {
      return this.$store.state[this.MODULE_NAME].serviceOrdersForm
    },
  },

  setup() {
    const { MODULE_NAME, serviceOrdersFields, ACCESS_ABILITY_SETTINGS_ORDERS } = config()

    return {
      MODULE_NAME,
      serviceOrdersFields,
      getProps,
      ACCESS_ABILITY_SETTINGS_ORDERS,
    }
  },
}
</script>
