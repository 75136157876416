<template>
  <div>
    <h2
      class="font-weight-bolder font-medium-4"
    >
      {{ $t('Service Quotes') }}
    </h2>

    <b-row>
      <b-col md="12">
        <div
          v-for="field in ['service_orders_enable_in_house']"
          :key="field"
        >
          <component
            :is="serviceOrdersFields[field].type"
            :ref="`${field}_ref`"
            v-model="serviceOrders[field]"
            v-bind="getProps(field, serviceOrdersFields)"
            :is-editable="isEdit"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { getProps } from '@core/utils/utils'
import { BCol, BForm, BRow } from 'bootstrap-vue'
import config from '@/views/settings/orders/service-orders/serviceOrdersConfig'

export default {
  name: 'ServiceOrdersQuotes',
  components: {
    BForm, BRow, BCol, ErrorAlert,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
    }
  },
  computed: {
    serviceOrders() {
      return this.$store.state[this.MODULE_NAME].serviceOrdersForm
    },
  },
  setup() {
    const { MODULE_NAME, serviceOrdersFields, ACCESS_ABILITY_SETTINGS_ORDERS } = config()

    return {
      MODULE_NAME,
      serviceOrdersFields,
      ACCESS_ABILITY_SETTINGS_ORDERS,
      getProps,
    }
  },
}
</script>
<style scoped>
</style>
